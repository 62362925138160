<template>
    <base-card v-if="fixture.matchResult">
        <v-card-text>
            <div
                class="d-flex justify-space-between align-center flex-wrap m-3"
            >
                <div class="flex-1 d-flex align-center">
                    <v-img contain :style="calculateIconColour('HOME_WIN')" :max-width="calculateIconSize('HOME_WIN')" :src="`/assets/gaa/teams/icons/${fixture.homeLogo}.WEBP`" :alt="fixture.homeTeam" class="img_border"></v-img>
                    <div class="flex-grow-1 ml-2">
                        <p class="font-weight-semi ma-0" :class="resultClass('home')">
                            {{ fixture.homeTeam }}
                        </p>
                    </div>
                </div>
                <div v-if="!($vuetify.breakpoint.xsOnly)" class="flex-1 d-flex text-center">
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            <span v-html="scoreString"></span>
                        </p>
                        <p v-if="!summary" class="font-weight-light text-caption ma-0">
                            <span>Margin {{  prediction.fixtureEnt.winningMargin ? prediction.fixtureEnt.winningMargin.label : '' }}</span>
                        </p>
                    </div>
                </div>
                <div class="flex-1 d-flex align-center">
                    <div class="flex-grow-1 text-right mr-2">
                        <p class="font-weight-semi ma-0" :class="resultClass('AWAY_WIN')">
                            {{ fixture.awayTeam }}
                        </p>
                    </div>
                    <v-img contain :style="calculateIconColour('AWAY_WIN')" :max-width="calculateIconSize('AWAY_WIN')" :src="`/assets/gaa/teams/icons/${fixture.awayLogo}.WEBP`" :alt="fixture.awayTeam" class="img_border"></v-img>
                </div>
                <div class="flex-1 mt-4 text-center" style="min-width: 263px">
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            <span v-html="resultString"></span>
                        </p>
                    </div>
                </div>
            </div>
            <v-divider class="mt-2 mb-3" />
            <div class="text-center mt-3">Points Earned: <b>{{pointsEarned}}</b></div>
        </v-card-text>
    </base-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        },
        prediction: {
            type: Object,
            required: true
        },
        progressbarColor: {
            type: String,
            default: 'warning'
        },
        footerBtn: {
            type: String,
            default: 'Chat'
        },
        footerBtnTwo: {
            type: String,
            default: 'Profile'
        },
        footerBtnColor: {
            type: String,
            default: 'red'
        },
        footerBtnColorTwo: {
            type: String,
            default: 'blue'
        },
        summary: {
            type: Boolean,
            default: false
        },
        resultLabel: {
            type: String,
            default: ''
        },
        winnerLabel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            expand: false,
            result: 0,
            winner: null,
            margin: null,
            marginText: ''
        }
    },
    computed: {
        ...mapGetters(["getWinningMargins", "hasMadeSelection", "getGameWeekSelection", "getCompetitionPredictionsOpen"]),
        resultText() {
            if(this.winnerLabel == 'HOME_WIN'){
                return this.fixture.homeTeam + ' ' + this.resultLabel
            } else if (this.winnerLabel == 'AWAY_WIN'){
                return this.fixture.awayTeam + ' ' + this.resultLabel
            } else {
                return 'Draw';
            }
        },
        showScoreSelection(){
            return this.expand && this.result != -1
        },
        predictionText(){
            let prediction = this.$store.getters['getGameWeekSelection'].find(selection => selection.fixtureEnt.id == this.fixture.id)
            if(!prediction) return 'Not Selected'
            if(prediction.matchResult == 'HOME_WIN'){
                return this.fixture.homeTeam + ` ${prediction.winningMargin.label}`;
            } else if(prediction.matchResult == 'AWAY_WIN'){
                return this.fixture.awayTeam + ` ${prediction.winningMargin.label}`;
            }
            return 'Draw';
        },
        predictionColor(){
                return `color: ${this.fixture.homeColour}`;
        },
        resultString(){
            let text = '';
            let winningTeam = this.winnerLabel == 'HOME_WIN' ? this.fixture.homeTeam : this.fixture.awayTeam;
            let losingTeam = this.winnerLabel != 'HOME_WIN' ? this.fixture.awayTeam : this.fixture.homeTeam;
            if(this.prediction.matchResult == this.fixture.matchResult){
                if(this.fixture.matchResult == "DRAW"){
                    return '<span>Correctly predicted a </span><span style="color: green; font-size: large">draw</span>';
                }
                text = `<span>Correctly predicted </span><span style="color: green; font-size: large">  ${winningTeam}  </span><span> to win</span>`
                if(this.prediction.fixtureEnt.winningMargin.value == this.prediction.winningMargin.value){
                    text += ` <span> by</span><span style="color: green; font-size: medium">  ${this.prediction.winningMargin.label}  </span><span> points</span>`
                } else {
                    text += `, <span> but the margin of </span><span style="color: red;"> ${this.prediction.winningMargin.label} </span><span> points was incorrect</span>`
                }
            } else {
                if(this.prediction.matchResult == "DRAW"){
                    return '<span>Incorrectly predicted a </span><span style="color: red;">draw</span>';
                }
                text = `<span>Incorrectly predicted</span><span style="color: red;">  ${losingTeam} </span><span> to win by </span><span style="color: red;"> ${this.prediction.winningMargin.label}</span>`
            }
            return text;
        },
        scoreString(){
            let text = '';
            if(this.winnerLabel == 'HOME_WIN'){
                return `<span style="font-size: initial">${this.fixture.homeScore}</span><span>-${this.fixture.awayScore}</span>`
            } else {
                return `<span>${this.fixture.homeScore}-</span><span style="font-size: initial">${this.fixture.awayScore}</span>`
            }
        },
        pointsEarned() {
            let score = this.prediction.matchResult == this.fixture.matchResult ? 5 : 0;
            if(this.prediction.matchResult == this.fixture.matchResult && this.prediction.winningMargin.value == this.prediction.fixtureEnt.winningMargin.value){
                score += 8;
            }
            if(this.prediction.matchResult == this.fixture.matchResult && this.fixture.matchResult == "DRAW"){
                score = 17;
            }
            return score;
        }
    },
    methods: {
        ...mapActions(["makeSelection"]),
        resultClass(team){
            let classString = '';
            if(team == 'home'){
                if(this.fixture.matchResult == 'HOME_WIN'){
                    classString = 'win';
                }
                if(this.prediction.matchResult == this.fixture.matchResult && this.fixture.matchResult == 'HOME_WIN'){
                    classString += ' correct';
                } else if(this.prediction.matchResult == 'HOME_WIN') {
                    classString += ' wrong';
                }
            } else {
                if(this.fixture.matchResult == 'AWAY_WIN'){
                    classString = 'win';
                }
                if(this.prediction.matchResult == this.fixture.matchResult && this.fixture.matchResult == 'AWAY_WIN'){
                    classString += ' correct';
                } else if(this.prediction.matchResult == 'AWAY_WIN'){
                    classString += ' wrong';
                }
            }

            return classString;
        },
        calculateIconSize(winningResult){
            if(this.$vuetify.breakpoint.xsOnly || this.summary){
                return this.fixture.matchResult == winningResult ? 50 : 30;
            } else {
                return this.fixture.matchResult == winningResult ? 80 : 50;
            }
        },
        calculateIconColour(winningResult){
            return this.fixture.matchResult == winningResult ? '' : this.fixture.matchResult == 'DRAW' ? 'filter: grayscale(0.5);' : 'filter: grayscale(0.95);'
        }
    }
}
</script>

<style scoped>
 .active {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 9px 1px #9ecaed;
    /* box-shadow: 0 0 6px 3px #44a0ea; */
 }

 .correct {
    color: green;
 }

 .wrong {
    color: red;
 }

 .win {
    font-weight: bold;
    font-size: larger;
 }

 .lose {
    font-weight: lighter;
    font-size: small;
 }

 .light {
    font-weight: light;
 }

 .grid-container {
  display: grid;
  grid-template-columns: auto auto; /* Two columns: one for labels, one for data */
  gap: 10px; /* Optional: adds space between grid items */
  justify-items: start; /* Aligns content to the left inside each grid item */
  /* You can also use justify-content if you want to align the whole grid to the left */
}

.grid-container > div {
  /* Styling for the grid items */
}

.img_border {
    border-style: solid;
    border-width: 2px;
}
</style>
